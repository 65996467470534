

:root {
  --header-size: 60px;
}

@media only screen and (max-width: 640px) {

  :root {
    --header-size: 105px;
  }

}
