@import "assets/colors";
@import "assets/vars";

app-root {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
}

#main-content {
  font-size: 12px !important;
}

.main-content {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  border-radius: 0px;
  width: 100%;
  margin-top: var(--header-size);
  max-width: 100vw !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: transparent;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  border-top: 1px solid #e0e0e0;
  //background: #e6f7ff;
  background: #eee;
}

.ant-table-tbody > tr.ant-table-row > td {
  border-top: 1px solid transparent;
}

.ant-tabs-nav-more {
  color: white !important;
}

.ant-layout-header {
  line-height: normal;
  height: 70px;
}

.ant-tabs-tab-remove {
  color: white !important;

  &:hover {
    color: #AB9282 !important;
  }
}

.ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before, .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before {
  border: none !important;
}

.ant-tree .ant-tree-switcher {
  line-height: 18px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before, .ant-spin-dot-item {
  background-color: transparent !important;
}

.ativo, .desativado, .nao-ativado {
  text-transform: capitalize;
  color: white;

  &:hover {
    color: white;
    border-color: transparent;
  }
}

.ativo {
  background-color: $success;

  &:hover, &:active, &:focus {
    background-color: rgba($success, 0.9);
    color: white;
  }
}

.desativado {
  background-color: $danger;

  &:hover, &:active, &:focus {
    background-color: rgba($danger, 0.9);
    color: white;
  }
}

.nao-ativado {
  color: rgba(0, 0, 0, .25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;

  &:hover, &:active, &:focus {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.custom-radio-group {
  .ant-radio-button-wrapper-checked:not(.nao-ativado) {
    color: white;
  }

  .ativo {
    background-color: $success;

    &:hover, &:active, &:focus {
      background-color: rgba($success, 0.9);
      color: white;
    }
  }

  .desativado {
    background-color: $danger;

    &:hover, &:active, &:focus {
      background-color: rgba($danger, 0.9);
      color: white;
    }
  }

  .nao-ativado {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;

    &:hover, &:active, &:focus {
      color: rgba(0, 0, 0, .25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.centralized-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}


.loading-icon {
  font-size: 68px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-card {
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
  border-radius: .45rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 1.875rem;
}

.pull-up:hover {
  transform: translateY(-4px) scale(1.02);
  box-shadow: 0px 14px 24px rgb(62 57 107 / 20%);
  z-index: 30;
}

.pull-up {
  transition: all 0.25s ease;
}

.ant-card-table {

  .ant-card-body {

    padding: 0px;

  }

}


//nz-table {
//  border-color: transparent;
//  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
//  border-radius: .45rem;
//}

//.ant-table, .ant-table-container, .ant-table-container table>thead>tr:first-child th:first-child {
//  border-top-left-radius: .45em;
//}
//
//.ant-table, .ant-table-container, .ant-table-container table>thead>tr:first-child th:last-child {
//  border-top-right-radius: .45em;
//}

.align-items-end {
  align-items: end !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.d-flex {
  display: flex !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.table-small {

  max-width: 100%;
  overflow-x: auto;

  td.ant-table-cell, th.ant-table-cell {
    padding: 8px !important;
  }

  .ant-table-pagination {
    margin-right: 15px;
  }

  .ant-pagination-total-text {
    display: block;
    position: absolute;
    left: 15px;
  }

}

.fonte-doze tr {
  font-size: 12px;
}

.notify-container {
  border: 1px rgba(0, 0, 0, 0.2) solid;
  border-radius: 2px;
}

.notify-header {
  background-color: #fff;
  padding: 5px;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.notify-scrollbar {
  max-height: 468px;
  overflow-y: scroll;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

.notify-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.notify-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.notify-scrollbar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.arrow {
  position: absolute;
  transform: rotate(180deg);
  left: 70px;
  top: 110px;
}

.arrow span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid #964b00;
  border-right: 5px solid #964b00;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

$prefix: "--"; //Prefix string for custom CSS properties

//Merges a variable name with $prefix
@function custom-property-name($name) {
  @return $prefix + $name;
}

// Defines a custom property
@mixin define-custom-property($name, $value) {
  #{custom-property-name($name)}: $value;
}

window, body, * {

  /*@each $name, $value in $colors {
    @include define-custom-property($name, $value);
  }*/

  // @include define-custom-property('background-primary', $dark-gray);

}

.table-scroller {
  position: fixed;
  right: 25px;
  bottom: 15px;
  z-index: 9999;
}

.card-fullscreen {
  display: block !important;
  z-index: 9999 !important;
  position: fixed !important;
  width: 99% !important;
  height: 99% !important;
  top: 5px !important;
  right: 5px !important;
  left: 5px !important;
  bottom: 10px !important;
  overflow: auto !important;
}

.highcharts-credits {
  display: none !important;
}

.ant-card-head, .ant-table-column-title, .ant-table-thead > tr > th, .ant-modal-title {
  font-weight: normal;
}

.ant-pagination-item, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}


.table-embedded {
  width: 96%;
  margin-top: 10px;
  font-size: 12px;
  margin: 0 auto;
}

.table-embedded th {
  background-color: #f7e5d9;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  padding: 5px;
}

.table-embedded tr {
  background-color: #f5f4f3;
  border-bottom: 1px solid #ddd;
}

.table-embedded td {
  padding: 5px;
}

// Custom alert ------------------------------

.alert {
  text-align: center;
  line-height: 20px;
  display: inline-block;
  padding: 3px 5px;
  border-radius: 3px;
  min-width: 100px;
  margin: 0 auto;
}

.warning {
  color: rgb(255, 145, 73);
  background-color: rgba(255, 145, 73, 0.1);
  border: 1px solid rgb(255, 145, 73);
}

.error {
  color: rgb(255, 73, 97);
  background-color: rgba(255, 73, 97, 0.1);
  border: 1px solid rgb(255, 73, 97);
}

.info {
  color: rgb(21, 101, 192);
  background-color: rgba(21, 101, 192, 0.1);
  border: 1px solid rgb(21, 101, 192);
}

.success {
  color: rgb(66, 186, 150);
  background-color: rgb(66, 186, 150, 0.05);
  border: 1px solid rgb(66, 186, 150);
}


.default {
  color: rgb(97, 97, 97);
  background-color: rgba(97, 97, 97, 0.1);
  border: 1px solid rgb(97, 97, 97);
}

.semCor {
  color: rgb(75, 75, 75);
  background-color: rgba(238, 238, 238, 0.1);
  border: 1px solid rgb(75, 75, 75);
}

// --------------------Notificações--------------------------

.badge-manual {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 16px;
  height: 16px;
  padding: 4px 2px 2px 2px;
  border-radius: 50px;
  font-size: 0.9em;
  position: absolute;
  top: -5px;
  left: 31px;
}

.caixa-notificacao {
  margin: 8px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 5px;

  h5 {
    color: #484848;
    margin-bottom: 8px;
  }

  .fa-trash-can {
    color: rgba(206, 70, 70, 0.55);
  }
}
